import PropTypes from 'prop-types';
import { stars } from '../../Model/Model';
import { StarFill } from 'react-bootstrap-icons';

const RatingStars = ({ ratingValue, className }: stars) => {
  let starset: any = [];
  for (let index = 0; index < ratingValue; index++) {
    starset.push(<StarFill key={index} className={className} />);
  }
  return starset;
};

RatingStars.propTypes = {
  ratingValue: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default RatingStars;
