import React from "react";

const ProductTestimonialsWidget = (props: any) => {
  const data = props.landing ? props.data?.ReviewDetails[0] : props.data;
  return (
    <>
      <div
        className={`widget-container ${
          props.landing && `widget-container-landing`
        }`}
      >
        <div className="widget-thumbnail">
          <img className="widget-img" src={props.data?.image?.url} />
        </div>
        <div
          className={`testimony-body-container ${
            !props.landing && `body-max-width`
          }`}
        >
          <div className="testimony-body">{`"${data?.Review}"`}</div>
          <div className="testimony-review">
            <span className="testimony-name">{data?.CustomerName}</span>{" "}
            {data?.CustomerLocation}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTestimonialsWidget;
